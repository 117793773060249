// i18next-extract-mark-ns-start paypal-for-prestashop
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import prestashop_paypal from 'images/prestashop_paypal.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import {List, OrderedList} from 'components/List';
import {IndexBackground} from 'components/landings/IndexBackground';
import {Bold} from 'components/StickyBanner';
import {MainTitleContent} from 'components/combo-pages/MainTitleContent';
import {MainTitleImg} from 'components/combo-pages/MainTitleImg';
import {BlogLink} from 'components/links/Blog';
import {
  InternalPageLink,
  PaymentsOrchestrationLink,
  PrestashopPaymentGatewayLink,
  PricingLink,
  RedsysAlternativeLink
} from 'components/links/Pages';
import {SignUpLink} from 'components/links/Other';
import {SetupIntegration} from 'components/combo-pages/SetupIntegration';

const PaypalForPrestashop: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="paypal-for-prestashop"
        title={t('PayPal PrestaShop')}
        description={t(
          'Add the PayPal PrestaShop integration to your store to let customers pay with the most popular digital wallet in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '615px', md: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Get the PayPal PrestaShop integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                PayPal is the most popular digital wallet globally, making it vital to accept PayPal
                payments for PrestaShop. But in order to do this, you’ll need a PrestaShop payment
                gateway that supports the PrestaShop PayPal integration.
              </Trans>
              <Trans parent="p">
                <Bold>Get MONEI</Bold> to accept PayPal and more payment methods for PrestaShop
                while managing it all from a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={prestashop_paypal}
              alt="Prestashop paypal"
              title="Prestashop paypal"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept PrestaShop PayPal payments (and more)</Trans>
              </SectionHeader>
              <Trans parent="p">
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink> is widely
                used, but there’s an abundance of{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> you can
                add to your PrestaShop store to boost customer satisfaction and sales.
              </Trans>
              <Trans parent="p">
                <InternalPageLink slug="payment-methods/credit-cards">
                  Credit and debit cards
                </InternalPageLink>{' '}
                are expected, but you can also meet the demands of shoppers (in Spain and{' '}
                <BlogLink slug="cross-border-ecommerce">across borders</BlogLink>) by offering more{' '}
                <BlogLink slug="alternative-payment-methods">alternative</BlogLink> and{' '}
                <BlogLink slug="local-payment-methods">local payment methods</BlogLink> including:
              </Trans>
              <List>
                <Trans parent="li">
                  <BlogLink slug="digital-wallet-ecommerce">Digital wallets</BlogLink> like{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,
                  and{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  <Link to="/bizum-for-prestashop/">Bizum</Link> peer-to-peer (P2P) payments (Spain)
                </Trans>
                <li>
                  <Trans>
                    <InternalPageLink slug="payment-methods/multibanco">
                      Multibanco
                    </InternalPageLink>{' '}
                    bank transfer (Portugal)
                  </Trans>
                </li>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>{' '}
                  bank transfer (Belgium)
                </Trans>
              </List>
              <Trans parent="p">
                Reach customers across Europe with more online payment methods for PrestaShop while
                improving the payment experience and boosting your conversion rate with the best{' '}
                <PrestashopPaymentGatewayLink>
                  PrestaShop payment gateway
                </PrestashopPaymentGatewayLink>{' '}
                integration.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Optimize your sales by approving more online payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Reduce false{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                and lost sales by setting up{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4412643058833">
                  payment processing rules
                </AnchorLink>
                . Instead of depending on <RedsysAlternativeLink>Redsys</RedsysAlternativeLink>{' '}
                alone to process transactions, use{' '}
                <PaymentsOrchestrationLink>payments orchestration</PaymentsOrchestrationLink> to
                choose a default payment processor. And if the provider is experiencing downtime,
                we’ll send the payment to a different, better-performing one,{' '}
                <Bold>increasing approved payments by up to 95%</Bold>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Get the cheapest PrestaShop payment gateway</Trans>
              </SectionHeader>
              <Trans parent="p">
                While you’re growing a business, every cent counts. Enjoy the benefits of{' '}
                <PricingLink>scaled pricing</PricingLink>. As you sell more, your transaction fees
                will decrease in real-time. Choose the most flexible PrestaShop payment gateway.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <SetupIntegration>
        <SectionHeader underline tagName="h3">
          <Trans>Set up your PayPal PrestaShop integration</Trans>
        </SectionHeader>
        <Trans parent="p">
          Follow these steps to quickly and easily add a PayPal button to your PrestaShop store:
        </Trans>
        <OrderedList>
          <Trans parent="li">
            <SignUpLink>Sign up for MONEI</SignUpLink>
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://support.monei.com/hc/articles/360017812937-How-do-I-add-PayPal-to-my-account-">
              Configure PayPal payments
            </AnchorLink>{' '}
            in your MONEI dashboard
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/prestashop/">
              Connect MONEI
            </AnchorLink>{' '}
            to your PrestaShop store
          </Trans>
        </OrderedList>
      </SetupIntegration>
    </>
  );
};

export default PaypalForPrestashop;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "paypal-for-prestashop"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
